import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import Slider from 'react-slick'
import { Section, FullWidth, Row} from '../styled'
import { device } from '../helpers/constants'

const Paging = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    top: calc(50% - 1px);
    background: #C1C8D3;
    z-index: -1;

    @media ${device.tablet} {
      top: calc(50% - 17px);
    }
  }
`

const PagingNum = styled.span`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #C1C8D3;
  border-radius: 100%;
  background: white;
  transition: background 0.25s, border-color 0.25s;

  @media ${device.tablet} {
    width: 36px;
    height: 36px;
  }
`

const PagingLabel = styled.span`
  display: none;

  .slick-active & {
    color: #F1698D;
  }

  @media ${device.tablet} {
    display: block;
  }
`

const Dots = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  margin-top: 4vw;

  li {
    flex: 1;
    font-weight: 600;
    color: #C1C8D3;
    margin-bottom: 0;
    cursor: pointer;
    transition: color 0.25s;

    &:first-child ${Paging}:before {
      left: 50%;
    }

    &:last-child ${Paging}:before {
      right: 50%;
    }

    &:hover {
      color: #4C5467;
    }

    &.slick-active {
      color: white;

      ${PagingNum} {
        border-color: #F1698D;
        background: #F1698D;
      }
    }
  }
`

const Content = styled.div`
  text-align: center;
  margin-top: 2.4rem;

  p:last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet}{
    text-align: left;
    margin-top: 0;
  }
`

const Slide = ({ slide }) => (
  <Row>
    {slide.image && slide.image.localFile && <Img fluid={slide.image.localFile.childImageSharp.fluid} alt={slide.image.alt_text} />}
    <Content>
      {slide.title && <h2>{slide.title}</h2>}
      {slide.text && <div dangerouslySetInnerHTML={{__html: slide.text}} />}
    </Content>
  </Row>
)

const SliderComp = ({ slides, bg }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    dotsClass: `slider-nav`,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => <Dots>{dots}</Dots>,
    customPaging: index => (
      <Paging>
        <PagingNum>{index+1}</PagingNum>
        <PagingLabel>{slides[index].label}</PagingLabel>
      </Paging>
    )
  }

  return (
    <Section bg={bg ? bg : null}>
      <Slider {...settings}>
        {slides.map((slide, index) => <Slide key={index} slide={slide} />)}
      </Slider>
    </Section>
  )
}

const ExplanationSliderBlock = ({ slides, full_width, background_color }) => (
  <>
    <Helmet>
      <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
      <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
    </Helmet>

    {!full_width ? (
      <SliderComp slides={slides} bg={background_color} />
    ) : (
      <FullWidth bg={background_color ? background_color : null}>
        <SliderComp slides={slides} />
      </FullWidth>
    )}
  </>
)

export default ExplanationSliderBlock