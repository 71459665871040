import React, { useState, useRef, useEffect } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import { SectionCenter } from '../styled'
import { Intro } from './content-intro-text'
import { trackFaqCampaign } from '../../helpers/campaigns'
// import { device } from '../helpers/constants'

const Accordion = styled.ul`
  margin: 0 auto;
  list-style: none;
`

const QuestionTitle = styled.h4`
  padding: 2.4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  cursor: pointer;
`

const Answer = styled.div`
  opacity: 0; 
  visibility: hidden;
  height: ${props => props.answerHeight && 0}px;
  overflow: hidden;
  transition: 0.25s;

  &[aria-hidden="false"]{
    opacity: 1; 
    visibility: visible;
    height: ${props => props.answerHeight && props.answerHeight}px;
  }
`

const AccordionItem = styled.li`
  margin-bottom: 0;
  text-align: left;

  &:not(:last-child) {
    border-bottom: 1px solid #C1C8D3;
  }

  svg {
    margin-left: 1.2rem;
    transition: 0.25s;
  }

  &:not(.is-active) {
    ${QuestionTitle}:hover {
      opacity: 0.7;
    }
  }

  &.is-active {
    padding-bottom: 2.4rem;

    svg {
      transform: rotate(-90deg);
    }
  }
`

const AccordionFaqItem = ({ q, index }) => {
  const [isActive, setIsActive] = useState(null)
  const [answerHeight, setAnswerHeight] = useState()
  const answerRef = useRef()

  useEffect(() => setAnswerHeight(answerRef.current.offsetHeight), [])

  const handleClick = () => {
    if (isActive === index) {
      setIsActive(null)
      trackFaqCampaign('Close', q.question)
    }
    else {
      setIsActive(index)
      trackFaqCampaign('Open', q.question)
     }
  }

  return (
    <AccordionItem
      className={isActive === index && `is-active`}
      role="button"
      aria-expanded={isActive === index}
    >
      <QuestionTitle onClick={handleClick}>
        {q.question}
        <FontAwesomeIcon icon={faChevronLeft} />
      </QuestionTitle>

      <Answer
        ref={answerRef}
        dangerouslySetInnerHTML={{__html: q.answer}}
        answerHeight={answerHeight && answerHeight }
        aria-hidden={isActive !== index}
      />
    </AccordionItem>
  )
}

const FaqBlock = ({ title, text, questions }) => {
  const mainEntity = questions.map(q => (
    {
      "@type": "Question",
      "name": q.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": q.answer.replace(/\n|\r/g, '')
      }
    }
  ))

  //https://developers.google.com/search/docs/data-types/faqpage
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": ${JSON.stringify(mainEntity)}
            }
          `}
        </script>
      </Helmet>

      <SectionCenter>
        {title && <h2>{title}</h2>}
        <Intro dangerouslySetInnerHTML={{__html: text}} />

        <Accordion>
          {questions.map((q, index) => <AccordionFaqItem key={index} q={q} index={index} />)}
        </Accordion>
      </SectionCenter>
    </>
  )
}

export default FaqBlock
