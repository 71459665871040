// import React, { useState } from 'react'
import React from 'react'
// import styled, { keyframes } from 'styled-components'
// import axios from 'axios'
// import { Banner, InnerContent, Form, Input, Submit } from './styled/banner'
import { Banner, InnerContent } from './styled/banner'
import { ButtonLink } from './styled'
import { useStaticQuery, graphql } from 'gatsby'
import { trackBetaCampaign } from '../helpers/campaigns'

// const Notice = styled.div`
//   width: 100%;
//   position: absolute;
//   bottom: -1em;
//   left: 50%;
//   transform: translateX(-50%);
// `

// const spin = keyframes`
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// `

// const Loader = styled.div`
//   height: 24px;
//   width: 24px;
//   border: 2px solid white;
//   border-radius: 100%;
//   border-top-color: transparent;
//   position: absolute;
//   right: 10px;
//   top: 50%;
//   margin-top: -12px;
//   animation: ${spin} 0.5s linear infinite;
// `

const JoinBeta = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "banner-join-beta.jpg" }) {
        childImageSharp {
          fixed(width: 1220, quality: 100) {
            src
          }
        }
      }
    }
  `)

  // const [formVals, setFormVals] = useState({})
  // const [success, setNotice] = useState()
  // const [error, setError] = useState()
  // const [isProcessing, setIsProcessing] = useState(false)

  // const handeChange = e => {
  //   setFormVals({ ...formVals, [e.target.name]: e.target.value })
  // }

  // const handleSubmit = e => {
  //   e.preventDefault()
  //   setNotice()
  //   setError()

  //   if (formVals.companyName && formVals.email) {
  //     setIsProcessing(true)

  //     axios.put(`https://api.sendgrid.com/v3/marketing/contacts`, JSON.stringify({
  //       'list_ids': [
  //         process.env.GATSBY_SENDGRID_LIST
  //       ],
  //       contacts: [
  //         {
  //           'email': formVals.email,
  //           'custom_fields': {
  //             'e1_T': formVals.companyName, // company_name
  //             'e3_T': 'private_beta' // registration_type
  //           }
  //         }
  //       ]
  //     }), {
  //       headers: {
  //         'Authorization': 'Bearer ' + process.env.GATSBY_SENDGRID_KEY,
  //         'Content-Type': 'application/json',
  //       }
  //     })
  //     .then(() => {
  //       setIsProcessing(false)
  //       setFormVals({})
  //       setNotice('Gelukt! Houd je inbox in de gaten, je ontvangt binnenkort een uitnodiging.')
  //     })
  //     .catch(err => {
  //       setIsProcessing(false)
  //       setError(`Er is iets mis gegaan, probeer het later nog eens. error: ${err.message}`)
  //     })
  //   } else {
  //     setError('Wat leuk dat je Swoof wilt proberen! We hebben wel je bedrijfsnaam en e-mailadres nodig.')
  //   }
  // }

  return (
    <Banner bg={data.placeholderImage.childImageSharp.fixed.src} id="join-beta">
      <InnerContent>
        <h2>Meld je gratis aan</h2>
        <p>
          Swoof is dit moment alleen te gebruiken op uitnodiging. Laat je gegevens achter en ontvang binnenkort jouw uitnodiging om direct te factureren met Swoof.
        </p>
        <ButtonLink onClick={() => trackBetaCampaign('Join Beta Banner')} to="/beta">Aanmelden beta</ButtonLink>
        {/* {!success ? (
          <Form onSubmit={handleSubmit}>
            <Input onChange={handeChange} name="companyName" placeholder="Bedrijfsnaam" />
            <Input type="email" onChange={handeChange} type="email" name="email" placeholder="E-mailadres"/>
            <Submit disabled={isProcessing || success || !formVals.companyName || !formVals.email} type="submit">
              Aanmelden
              {isProcessing && <Loader />}
            </Submit>
          </Form>
        ) : <p>{success}</p>}
        {error && <Notice>{error}</Notice>} */}
      </InnerContent>
    </Banner>
  )
}

export default JoinBeta
