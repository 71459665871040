import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const a = css`
  color: #12AEE5;
  font-weight: 600;
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  .svg-inline--fa {
    transform: translateX(6px);
    transition: 0.25s;
  }

  &:hover {
    .svg-inline--fa {
      transform: translateX(8px);
    }
  }
`

const GatsbyLink = styled(Link)`
  ${a}
`

const Anchor = styled.a`
  ${a}
`

const ButtonLink = ({ button_text, page }) => (
  button_text && page && (
    page.target !== '_blank'
    ? <GatsbyLink to={page.url.replace(process.env.ROOT_URL, '')}>
        {button_text}
        <FontAwesomeIcon icon={faChevronRight} />
      </GatsbyLink>
    : <Anchor target="_blank" rel="noopener" href={page.url}>
        {button_text}
        <FontAwesomeIcon icon={faChevronRight} />
      </Anchor>
  )
)

export default ButtonLink
