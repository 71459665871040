import React from 'react'
import styled from 'styled-components'

const Embed = styled.div`
  position: relative;
  padding-bottom: 56.25%;

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const ContentEmbedBlock = ({ embed }) => (
  <Embed dangerouslySetInnerHTML={{__html: embed}} />
)

export default ContentEmbedBlock
