import React, { useRef } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'
import { Section, Row } from '../styled'
import { device } from '../helpers/constants'
import ButtonLink from '../button-link'

const ZigZagItem = styled(Row)`
  .gatsby-image-wrapper {
    margin-top: 2.4rem;

    img {
      margin-bottom: 0;
    }
  }

  *:last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    .gatsby-image-wrapper {
      margin-top: 0;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      & > *:first-child {
        margin-right: 0;
        margin-left: 2vw;
      }

      & > *:last-child {
        margin-left: 0;
        margin-right: 2vw;
      }
    }
  }
`

const Footer = styled.footer`
  margin: 2.4rem 0;
`

 const Video = ({ src }) => {
  const videoRef = useRef()

  const stopVideo = () => {
    const vid = videoRef.current
    vid.currentTime = 0
    vid.pause()
  }

  return (
    <Waypoint
      onEnter={() => videoRef.current.play()}
      onLeave={stopVideo}
    >
      <video ref={videoRef} muted loop>
        <source src={src} type="video/mp4"></source>
      </video>
    </Waypoint>
  )
}

const Item = item => (
  <ZigZagItem>
    <div>
      <h3>{item.title}</h3>
      <div dangerouslySetInnerHTML={{__html: item.text}} />

      {item.button_text && item.page && (
        <Footer>
          <ButtonLink button_text={item.button_text} page={item.page} />
        </Footer>
      )}
    </div>

    {item.video && item.video.localFile.publicURL
      ? <Video src={item.video.localFile.publicURL} />
      : item.image && item.image.localFile && <Img fluid={item.image.localFile.childImageSharp.fluid} alt={item.image.alt_text} />
    }
  </ZigZagItem>
)

const ZigZag = ({ content }) => (
  <Section>
    {content.map((item, index) => <Item key={index} {...item} />)}
  </Section>
)

export default ZigZag