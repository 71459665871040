import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Section, FullWidth, Row } from '../styled'
import { device } from '../helpers/constants'
import ButtonLink from '../button-link'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2.4rem;
  row-gap: 2.4rem;
  margin-bottom: 2.4rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  *:last-child {
    margin-bottom: 0;
  }
`

const Title = styled.h2`
  margin-bottom: 3.8rem;
`

const Content = styled.div`
  margin-top: 3.8rem;
  order: 1;

  @media ${device.laptop} {
    margin-top: 0;
    order: 0;
  }
`

const Column = ({ column }) => {

  return (
    <div>
      {column.title && <h4>{column.title}</h4>}
      {column.text && <div dangerouslySetInnerHTML={{__html: column.text}} /> }
    </div>
  )
}

const FeatureComp = ({ title, columns, bg, color, image, button_text, page }) => (
  <Section bg={bg ? bg : null} color={color ? color : null}>
    <Row alignItems="flex-start" mq={device.laptop}>
      <Content>
        {title && <Title>{title}</Title>}

        <Grid>
          {columns.map((column, index) => <Column key={index} column={column} />)}
        </Grid>

        <ButtonLink button_text={button_text} page={page}/>
      </Content>
      {image && image.localFile && <Img fluid={image.localFile.childImageSharp.fluid} alt={image.alt_text}/>}
    </Row>
  </Section>
)

const FeatureRowBlock = ({ background_color, text_color, full_width, ...rest }) => (
  <>
    {!full_width ? (
      <FeatureComp bg={background_color} color={text_color} {...rest} />
    ) : (
      <FullWidth bg={background_color ? background_color : null} color={text_color ? text_color : null}>
        <FeatureComp {...rest}/>
      </FullWidth>
    )}
  </>
)

export default FeatureRowBlock
