import React from 'react'
import styled from 'styled-components'

export const Intro = styled.div`
  font-size: 2.4rem;
  font-size: var(--font-size-intro);
  font-weight: 500;
`

const ContentIntroTextBlock = ({ text }) => (
  <Intro dangerouslySetInnerHTML={{__html: text}} />
)

export default ContentIntroTextBlock
