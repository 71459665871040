import React from 'react'
import styled from 'styled-components'
// import { ButtonAnchor, ButtonLink } from '../styled'
import { ButtonLink } from '../styled'
import { trackBetaCampaign } from '../../helpers/campaigns'

const Wrap = styled.div`
  padding: 6rem;
  text-align: center;
  border-radius: 8px;
  /* box-shadow: 0 4px 12px rgba(0,12,38, 0.24); */
  background: #d7f3fd;

  &:not(:last-child) {
    margin-bottom: 2.4rem;
    margin-bottom: 8vw;
  }
`

// const ActionButton = styled(ButtonAnchor)`
//   background: #FF9000;
// `

const CallToAction = () => (
  <Wrap>
    <h3>Aan de slag met Swoof?</h3>
    <p>Met Swoof maak je professionele facturen voor jouw bedrijf. <br/>Versturen kan per e-mail of WhatsApp direct vanuit ons platform. Makkeljker kan niet.</p>
    {/* <ActionButton href={`${process.env.GATSBY_APP_URL}/signin/signup`} target="_blank" rel="noopener">Probeer gratis</ActionButton> */}
    <ButtonLink onClick={() => trackBetaCampaign('Call To Action Block')} to="/beta">Aanmelden beta</ButtonLink>

  </Wrap>
)

export default CallToAction
