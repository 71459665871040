import React from 'react'
import { SectionCenter } from '../styled'
import { Intro } from './content-intro-text'

const CenterTextBlock = ({ title, text }) => (
  <SectionCenter>
    <h2>{title}</h2>
    <Intro dangerouslySetInnerHTML={{__html: text}} />
  </SectionCenter>
)

export default CenterTextBlock