import React from 'react'
import styled from 'styled-components'
import { Section, ButtonAnchor, ButtonLink } from '../styled'

const PositionSection = styled(Section)`
  text-align: ${(props) => props.position};
`

const ButtonBlock = ({ url, button_text, target, position }) => {
  if (url === process.env.ROOT_URL) url = '/'

  return (
    <PositionSection position={position}>
      {target === '_blank'
        ? <ButtonAnchor target={target} rel="noopener" href={url}>{button_text}</ButtonAnchor>
        : <ButtonLink to={url.replace(process.env.ROOT_URL, '')}>{button_text}</ButtonLink>
      }
    </PositionSection>
  )
}

export default ButtonBlock
