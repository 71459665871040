import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Section, FullWidth } from '../styled'
import { device } from '../helpers/constants'
import ButtonLink from '../button-link'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 8vw;
  row-gap: 8vw;
  margin-top: ${props => props.hasTitle ? `8vw` : null};

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 6vw;
    row-gap: 6vw;
    margin-top: ${props => props.hasTitle ? `6rem` : null};
  }

  @media ${device.laptop} {
    grid-template-columns: repeat(${props => props.maxColumns}, 1fr);
  }

  img[src$=svg] {
    max-width: 60px;
  }

  *:last-child {
    margin-bottom: 0;
  }
`

const Header = styled.header`
  display: flex;
  align-items: center;

  h2 {
    margin-bottom: 0;
  }

  img {
    max-width: 40px;
    height: auto;
    margin-right: 1.2rem;
    margin-bottom: 0;
    
    @media ${device.tablet} {
      max-width: 60px;
      margin-right: 2.4rem;
    }

    @media ${device.laptop} {
      max-width: 80px;
    }
  }
`

const Footer = styled.footer`
  margin-top: 2.4rem;
  display: flex;

  @media ${device.tablet} {
    margin-top: 4.8rem;
    justify-content: center;
  }
`

const SectionCenterText = styled(Section)`
  ${Header} {
    @media ${device.tablet} {
      justify-content: center;
      text-align: center;
    }
  }

  &.center-text {
    text-align: center;
  }
`

const Column = ({ column }) => {
  const { image, svg_icon } = column

  return (
    <div>
      {image && <Img fluid={image.localFile.childImageSharp.fluid} alt={image.alt_text}/> }
      {svg_icon && <img src={svg_icon.sizes.large.localFile.url} alt={svg_icon.alt}/> }
      {column.title && <h4>{column.title}</h4>}
      {column.text && <div dangerouslySetInnerHTML={{__html: column.text}} /> }
    </div>
  )
}

const ColumnComp = ({ columns, bg, color, title, icon, maxColumns, centerText, button_text, page }) => (
  <SectionCenterText bg={bg ? bg : null} color={color ? color : null} className={centerText && 'center-text'}>
    <Header>
      {icon && <img src={icon.sizes.large.localFile.url} alt={icon.alt}/> }
      {title && <h2>{title}</h2>}
    </Header>

    <Grid maxColumns={maxColumns} hasTitle={title ? 1 : 0}>
      {columns.map((column, index) => <Column key={index} column={column} />)}
    </Grid>

    {button_text && page && (
      <Footer>
        <ButtonLink button_text={button_text} page={page} />
      </Footer>
    )}
  </SectionCenterText>
)

const ColumnsRowBlock = ({ title, svg_icon, columns, max_columns, full_width, background_color, text_color, center_text, button_text, page }) => (
  <>
    {!full_width ? (
      <ColumnComp columns={columns} bg={background_color} color={text_color} title={title} maxColumns={max_columns} centerText={center_text} icon={svg_icon} button_text={button_text} page={page} />
    ) : (
      <FullWidth bg={background_color ? background_color : null} color={text_color ? text_color : null}>
        <ColumnComp columns={columns} title={title} maxColumns={max_columns} centerText={center_text} icon={svg_icon} button_text={button_text} page={page} />
      </FullWidth>
    )}
  </>
)

export default ColumnsRowBlock
