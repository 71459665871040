import React from 'React'

import ButtonBlock from './blocks/button'
import CallToAction from './blocks/call-to-action'
import CenterTextBlock from './blocks/center-text'
import ColumnsRowBlock from './blocks/columns-row'
import ContentIntroTextBlock from './blocks/content-intro-text'
import ContentTextBlock from './blocks/content-text'
import ContentEmbedBlock from './blocks/content-embed'
import ContentImageBlock from './blocks/content-image'
import ExplanationSliderBlock from './blocks/explanation-slider'
import FaqBlock from './blocks/faq'
import FeatureRowBlock from './blocks/feature-row'
import MediaBlock from './blocks/media'
import PricesBlock from './blocks/prices'
import SmallHeroBlock from './blocks/small-hero'
import TipBlock from './blocks/tip'
import ZigZagBlock from './blocks/zigzag'

const acfBlocks = {
  'WordPressAcf_button': ButtonBlock,
  'WordPressAcf_call_to_action': CallToAction,
  'WordPressAcf_center_text': CenterTextBlock,
  'WordPressAcf_columns_row': ColumnsRowBlock,
  'WordPressAcf_content_intro_text': ContentIntroTextBlock,
  'WordPressAcf_content_text': ContentTextBlock,
  'WordPressAcf_content_embed': ContentEmbedBlock,
  'WordPressAcf_content_image': ContentImageBlock,
  'WordPressAcf_explanatiom_slider': ExplanationSliderBlock,
  'WordPressAcf_faq': FaqBlock,
  'WordPressAcf_feature_row': FeatureRowBlock,
  'WordPressAcf_media': MediaBlock,
  'WordPressAcf_prices': PricesBlock,
  'WordPressAcf_small_hero': SmallHeroBlock,
  'WordPressAcf_tip': TipBlock,
  'WordPressAcf_zigzag_content': ZigZagBlock
}

const dynamicBlock = block => {
  const Block = acfBlocks[block.__typename]
  return <Block key={block.id} {...block} />
}

const Blocks = ({ blocks }) => (
  <>
    {blocks.map(block => dynamicBlock(block))}
  </>
)

export default Blocks
