import React from 'react'
import styled from 'styled-components'
import { Section, ButtonLink } from '../styled'
import { device } from '../helpers/constants'
import { trackBetaCampaign } from '../../helpers/campaigns'

const Hero = styled(Section)`
  min-height: 340px;
  position: relative;
  background-image: ${props => `url(${props.image})`};
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 90%;
  border-radius: 8px;

  @media ${device.tablet} {
    height: 480px;
    background-size: 80%;
  }

  @media ${device.tablet} {
    background-size: contain;
  }
`

const FloatingContent = styled.div`
  position: relative;
  text-align: center;
  z-index: 1;
  margin-bottom: 20vh;

  @media ${device.tablet} {
    position: absolute;
    top: 50%;
    left: 4vw;
    transform: translateY(-50%);
    text-align: left;
    width: 40%;
  }
`

const SmallHeroBlock =  ({ title, text, image, background_color }) => (
  image ? (
    <Hero bg={background_color} image={image.localFile.childImageSharp.fixed.src}>
      <FloatingContent>
        <h2>{title}</h2>
        <div dangerouslySetInnerHTML={{__html: text}} />
        <ButtonLink onClick={() => trackBetaCampaign('Small Hero')} to="/beta">Direct aanmelden</ButtonLink>
      </FloatingContent>
    </Hero>
  ) : (
    null
  )
)

export default SmallHeroBlock
