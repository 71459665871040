import React from 'react'
import styled from 'styled-components'
import { Section, Row } from '../styled'
import { device } from '../helpers/constants'

const PricesRow = styled(Row)`
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
`

const Pricing = styled.div`
  padding: 2.4rem;
  border-radius: 8px;
  box-shadow: 0 3px 30px rgba(0,0,0, 0.1);
  color: ${props => props.dark && `white`};
  background: ${props => props.dark ? `#000C26` : `white`};
  
  &:first-of-type {
    margin-bottom: 2.4rem;

    @media ${device.tablet} {
      margin-bottom: 0;
    }
  }

  @media ${device.laptop} {
    transform: ${props => props.large && `scale(1.2)`};
  }

  * {
    color: ${props => props.dark && `white`};
  }
`

const Header = styled.header`
  margin-bottom: 2.4rem;
  color: #00C085;

  h2 {
    color: #00C085;
    margin-bottom: 0.4rem;
  }
`

const Small = styled.span`
  font-size: 80%;
  text-align: center;
  opacity: 0.6;
  display: block;

  @media ${device.tablet} {
    margin-top: -3vw;
  }
`

const PricesBlock = () => (
  <Section>
    <PricesRow>
      <Pricing large>
        <Header>
          <h2>Gratis</h2>
          <span>Kosteloos factureren</span>
        </Header>

        <ul className="checklist">
          <li>Ongelimiteerd factureren</li>
          <li>Financieel overzicht</li>
          <li>Handige contactenlijst</li>
          <li>Veel gebruikte producten</li>
        </ul>
      </Pricing>

      <Pricing dark>
        <Header>
          <h3>Waarom gratis?</h3>
        </Header>

        <p>
          Wij vinden dat iedere ondernemer zonder drempels moet kunnen factureren. Of je nu een beginnende ondernemer bent of al een paar jaar bezig bent. Swoof is er voor je.
        </p>
      </Pricing>
    </PricesRow>

    {/* <Small>Getoonde prijzen zijn exclusief 21% btw</Small> */}
  </Section>
)

export default PricesBlock
