import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Section, FullWidth } from '../styled'
import { device } from '../helpers/constants'
import { Intro } from './content-intro-text'

const Content = styled.div`
  text-align: center;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8vw;
  text-align: center;

  @media ${device.tablet} {
    margin-bottom: 6vw;
  }

  @media ${device.laptop} {
    margin-bottom: 4vw;
  }
`

const MediaImage = styled(Img)`
  max-width: 1000px;
  margin: 0 auto;
`

const MediaComp = ({ title, text, bg, color, image }) => (
  <Section bg={bg ? bg : null} color={color ? color : null}>
    <Content>
      {title && <h2>{title}</h2>}
      {text && <Intro dangerouslySetInnerHTML={{__html: text}} />}
    </Content>

    {image && image.localFile && <MediaImage fluid={image.localFile.childImageSharp.fluid} alt={image.alt_text}/>}
  </Section>
)

const MediaBlock =  ({ background_color, text_color, full_width, ...rest }) => (
  <>
    {!full_width ? (
      <MediaComp bg={background_color} color={text_color} {...rest} />
    ) : (
      <FullWidth bg={background_color ? background_color : null} color={text_color ? text_color : null}>
        <MediaComp {...rest}/>
      </FullWidth>
    )}
  </>
)

export default MediaBlock
