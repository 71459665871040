import styled from 'styled-components'
import { Button } from '../styled'
import { device } from '../helpers/constants'

export const Submit = styled.button`
  ${Button}
  position: relative;
  outline: none;
  flex: 1;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  &:disabled {
    background-image: none;
    background: #4C5467;
    cursor: default;
  }

  @media ${device.tablet} {
    border-radius: 24px;
    border-top-left-radius: 0;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 0;
  }
`

export const Banner = styled.div`
  width: 96vw;
  min-height: 440px;
  margin-left: 50%;
  transform: translateX(-50%);
  background: ${props => `url(${props.bg}) 10% 50% / cover no-repeat`};
  border-radius: 8px;
  margin-bottom: 2.4rem;

  @media ${device.tablet} {
    min-height: 420px;
  }

  @media ${device.laptop} {
    min-height: 480px;
    max-width: 1220px;
  }
`

export const InnerContent = styled.div`
  max-width: 680px;
  width: 92vw;
  padding: 24px;
  text-align: center;
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  h2 {
    color: inherit;
  }

  @media ${device.laptop} {
    width: 96vw;
  }
`

export const Input = styled.input`
  min-height: 48px;
  height: 48px;
  padding: 0 20px;
  border: none;
  outline: none;
  flex: 1;
  margin-bottom: 1px;
  border-radius: 0;
  appearance: none;

  &:first-of-type {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  @media ${device.tablet} {
    width: 0;
    display: inline-flex;
    align-items: center;
    margin-right: 1px;
    margin-bottom: 0;

    &:first-of-type {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      border-top-right-radius: 0px;
    }
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0;

  @media ${device.tablet} {
    flex-direction: row;
    border-radius: 24px;
  }
`