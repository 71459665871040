import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Image = styled(Img)`
  img {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }
`

const ContentImageBlock = ({ image }) => (
  <Image fluid={image.localFile.childImageSharp.fluid} alt={image.alt_text} />
)

export default ContentImageBlock
