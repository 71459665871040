import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'

const Ribon = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  padding: 6px 30px;
  background: white;
  transform: translate(25px, 5px) rotate(45deg);
  position: absolute;
  right: 0;
  top: 0;
  transition: transform 0.25s;

  .svg-inline--fa {
    margin-right: 4px;
  }
`

const Tip = styled.div`
  color: ${props => props.color};
  font-weight: 600;
  padding: 1em;
  border-radius: 4px;
  /* border: 1px solid ${props => props.backgroundColor}; */
  background-color: ${props => props.backgroundColor};
  position: relative;
  padding-right: 3em;
  overflow: hidden;
  box-shadow: 0 0 12px ${props => rgba(props.backgroundColor, 0.25)};

  *:last-child {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  ${Ribon} {
    color: ${props => props.backgroundColor};
    background-color: ${props => props.color};
  }
`

const TipBlock = ({ text, background_color, text_color }) => (
  <Tip
    backgroundColor={background_color}
    color={text_color}
  >
    <Ribon>
      <FontAwesomeIcon icon={faLightbulb} />
      Tip!
    </Ribon>
    <div dangerouslySetInnerHTML={{__html: text}} />
  </Tip>
)

export default TipBlock
